<template>
  <footer class="footer">
    <div class="has-text-centered">
      <p>
        <strong>Atena</strong> by
        <a href="https://alterdata.com.br">Alterdata</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  float: left;
  width: 100%;
  margin-top: 10px;
  background-color: whitesmoke;
  padding: 15px 0px 15px;
}
</style>


