<template>
  <div class="ajuste-posicao">
    <component
        v-bind:is="componenteSelecionado"
        :usuario-logado='obterUsuarioLogado'>
    </component>
  </div>
</template>

<script>
export default {
  props: {
    mfName: String
  },
  computed: {
    componenteSelecionado() {
      return this.mfName
    },
    obterUsuarioLogado() {
      return this.$store.state.usuarioLogado
    }
  }
}
</script>

<style lang="scss">
  .ajuste-posicao {
    margin-top: -38px;
  }
</style>