import Vue from "vue";
import App from "./App.vue";
import store from './store'
import router from "./router";

window.Vue = require('vue');

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['navbar-vanilla']

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
