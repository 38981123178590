<template>
  <div>
    <navbar-vanilla/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: {
        itens: [
          {
            titulo: "Sistemas",
            subitens: [
              {
                titulo: "Migração",
                evento: "evento-click-migracoes",
                rota: "/migracoes"
              },
              {
                titulo: "Passaporte",
                evento: "evento-click-passaporte",
                rota: "/passaporte"
              },
              {
                titulo: "Utilitários",
                evento: "evento-click-utils",
                rota: "/utils"
              }
            ]
          },
          {
            titulo: "Serviços",
            subitens: [
              {
                titulo: "DF-e",
                evento: "evento-click-dfe",
                rota: "/msdfe"
              },
              {
                titulo: "PIX",
                evento: "evento-click-mspix",
                rota: "/mspix"
              },
                {
                    titulo: 'MS-BI Shop',
                    evento: 'evento-click-msbishop',
                    rota: "/ms-bishop"
                },
              {
                titulo: "Cadastro Menu",
                evento: "evento-click-mscadastromenu",
                rota: "/mscadastromenu"
              },
              {
                titulo: "Mercado Pago",
                evento: "evento-click-msmercadopago",
                rota: "/msmercadopago"
              },
              {
                titulo: "Cadastro de artigos",
                evento: "evento-click-mscadastrokb",
                rota: "/mscadastrokb"
              }
              
            ]
          },
          {
            titulo: "Relatórios",
            subitens: [
              {
                titulo: "Consulta de boletos",
                evento: "evento-click-boleto",
                rota: "/boleto"
              },
              {
                titulo: "Homologacão NFS-e",
                evento: "evento-click-cidades-Homologacao",
                rota: "/msdfecidadeshomologacao"
              },
              {
                titulo: "Consultar Pix",
                evento: "evento-click-pix",
                rota: "/pix"
              },
              {
                titulo: "Telemetria ERP4ME",
                evento: "evento-click-telemetria",
                rota: "/telemetria"
              },

            ]
          },
          {
            titulo: "Cadastro de artigos",
            evento: "evento-click-mscadastroartigos",
            rota: "/mscadastroartigos"
          },
          {
            titulo: "ERP for Me",
            evento: "evento-click-erpforme",
            rota: "/erpforme"
          },
          {
            titulo: "home",
            evento: "evento-click-home",
            rota: "/"
          }
        ]
      }
    };
  },
  mounted() {
    this.setRotas();
    this.construirNavbar();
  },
  methods: {
    setRotas() {
      this.menu.itens.forEach(item => {
        document.addEventListener(item.evento, () => {
          this.$router.push(item.rota, () => {});
        });

        if (item.subitens) {
          item.subitens.forEach(subitem => {
            document.addEventListener(subitem.evento, () => {
              this.$router.push(subitem.rota, () => {});
            });
          });
        }
      })
    },
    construirNavbar() {
      this.navbar = new Navbar(this.getParametrosNavbar());
    },
    getParametrosNavbar() {
      var dropDowns = [];
      var itens = [];
      const navbarParameters = {
        backgroundColor: '#2e4283',
        hoverColor: 'rgb(59, 85, 169)',
        color: '#FFFFFF',
        customClass: 'custom-class-navbar',
        logos: [{
            src: '/image/ATENA-branco.png',
            href: '',
            clickEvent:'evento-click-home'
          }],
        dropDowns: dropDowns,
        items: itens,
        customElements: [
          {
              html: '<alt-sso clientid="shop-atena"></alt-sso>',
              position: 'right',
              idElement: 'alt-sso-button'
          }
        ]
      };

      if (process.env.VUE_APP_AMBIENTE === 'DEV') {
        itens.push(          
          {
            text: 'ERP for Me',
            customClass: 'cor-branca-ancora',
            href: '#',
            position: 'center',
            clickEvent: 'evento-click-erpforme'
          },
          {
            text: 'Utilitários',
            customClass: 'cor-branca-ancora',
            href: '#',
            position: 'center',
            clickEvent: 'evento-click-utils'
          }
        )

        dropDowns.push(
          {
            position: 'center',
            text: 'Sistemas',
            columns: [{
              contentBoxes: [{
                text: 'Módulos',
                items: [{
                  text: 'Migração',
                  href: '#',
                  clickEvent: 'evento-click-migracoes'
                },
                {
                  text: 'Passaporte',
                  href: '#',
                  clickEvent: 'evento-click-passaporte'
                }]
              }]
            }]
          },
          {
            position: 'center',
            text: 'Serviços',
            columns: [{
              contentBoxes: [{
                text: 'Módulos',
                items: [{
                  text: 'MS-DFe',
                  href: '#',
                  clickEvent: 'evento-click-dfe'
                },
                {
                  text: 'MS-Pix',
                  href: '#',
                  clickEvent: 'evento-click-mspix'
                },
                {
                  text: 'MS-BI Shop',
                  href: '#',
                  clickEvent: 'evento-click-msbishop'
                },
                {
                  text: 'MS-Cadastro Menu',
                  href: '#',
                  clickEvent: 'evento-click-mscadastromenu'
                },
                {
                  text: 'MS-Mercado pago',
                  href: '#',
                  clickEvent: 'evento-click-msmercadopago'
                },
                {
                  text: 'MS-Cadastro artigos',
                  href: '#',
                  clickEvent: 'evento-click-mscadastroartigos'
                }
                ],
              }]
            }]
          },
        )
      } else {
        dropDowns.push({
          position: 'center',
          text: 'Serviços',
          columns: [{
            contentBoxes: [{
              text: 'Módulos',
              items: [{
                text: 'MS-Cadastro artigos',
                href: '#',
                clickEvent: 'evento-click-mscadastroartigos'
              }],
            }]
          }]
        })
      };

      dropDowns.push(
      {
        position: 'center',
        text: 'Relatórios',
        columns: [{
          contentBoxes: [{
            items: [{
              text: 'Consulta Boletos',
              href: '#',
              clickEvent: 'evento-click-boleto'
            },
            {
              text: 'Consulta Pix',
              href: '#',
              clickEvent: 'evento-click-pix'
            },
            {
              text: 'Homologacão NFS-e',
              href: '#',
              clickEvent: 'evento-click-cidades-Homologacao'
            },
            {
              text: "Telemetria ERP4ME",
              href: '#',
              clickEvent: "evento-click-telemetria",
            }],
          }]
        }]
      },
    )

      return navbarParameters;
    }
  }
};
</script>

<style lang='scss' scoped>
@import url('../comum/styles/keycloak.css');
  input {
    font-size: 0.8rem !important;
    text-align: center !important;
  }

  .cor-branca-ancora {
    color: white !important;
  }

  a {
    color: white;
  }

  .content-column-header {
    padding-top: 9px !important;
  }
</style>