<template>
  <div>
    <span class="title">Passaporte</span>
  </div>
</template>

<script>
export default {
  
}
</script>

<style>

</style>