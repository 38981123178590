import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'
import Home from "../views/Home.vue";
import Passaporte from "../views/Passaporte.vue";
import RouterComponent from "../views/RouterComponent.vue";

Vue.use(VueRouter);

const routes = [
  {path: "/", name: "home", component: Home},
  {path: "/mscadastroartigos", component: RouterComponent, props: {mfName: 'mf-atena-cadastro-kb'}},
  {path: "/msdfecidadeshomologacao", component: RouterComponent, props: {mfName: 'mf-atena-msdfe-cidades-homologacao'}},
  {path: "/pix", component: RouterComponent, props: {mfName: 'mf-atena-dashboard-pix'}},
  {path: "/boleto", name: "boleto", component: RouterComponent, props: {mfName: 'atena-relatorio-boleto'}},
  {path: "/telemetria", name: "ms-rotinas-erpforme", component: RouterComponent, props: {mfName: 'mf-atena-telemetria'}},
];

const rotasDev = [
  {path: "/migracoes", name: "migração", component: RouterComponent, props: {mfName: 'mf-atena-migracoes'}},
  {path: "/passaporte", name: "passaporte", component: Passaporte},
  {path: "/msdfe", name: "ms-dfe", component: RouterComponent, props: {mfName: 'mf-atena-msdfe'}},
  {path: "/mspix", name: "ms-pix", component: RouterComponent, props: {mfName: 'mf-atena-pix'}},
  {path: "/mscadastromenu", name: "ms-menu", component: RouterComponent, props: {mfName: 'mf-atena-cadastro-menu'}},
  {path: "/erpforme/", name: "erpforme", component: RouterComponent, props: {mfName: 'atena-mf-erp4me-utilitarios'}},
  {path: "/erpforme/limpar-base-dados", name: 'limpar-base-dados', component: RouterComponent, props: {mfName: 'atena-mf-erp4me-utilitarios'}},
  {path: "/mscadastromenu", component: RouterComponent, props: {mfName: 'mf-atena-cadastro-menu'}},
  {path: "/utils", component: RouterComponent, props: {mfName: 'mf-atena-utils'}},
  {path: "/msmercadopago", component: RouterComponent, props: {mfName: 'mf-atena-mercado-pago'}},
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

if (process.env.VUE_APP_AMBIENTE === 'DEV') {
  router.addRoutes(
    rotasDev
  )
}

router.beforeResolve ((to, from, next) => {
    if (to.name !== 'home' && !store.state.autenticado) next({ name: 'home' })
    else next()
})


export default router;
